import { CourseFiltersInputs, CourseListProvider, CoursesListResponse } from '@/types/course.type';
import {
  ExamGenerationListResponse,
  ExamData,
  ExamFilters,
  AddFiltersResponse,
  CellDetailsResponse,
  GeneratedExamQuery,
  QuestionsFilters, CourseQuestionsData,
  ModelsDetails,
} from '@/types/exam.type';
import { Lang } from '@/types/general.type';
import provider from './main.provider';

class GenerateExamProvider implements CourseListProvider {
  async getAppCourseList(appId: string, query: CourseFiltersInputs): Promise<CoursesListResponse> {
    const courseFilters = { ...query, activeStatus: '1' };
    const result: CoursesListResponse = await provider.get(
      `/api/courses/${appId}/getList`,
      { params: courseFilters },
    );
    return result;
  }

  async getExamsGeneration(appId: string, courseId: string, query: object) {
    const result: ExamGenerationListResponse = await provider.get(
      `/api/generatedExam/${appId}/getExamsGeneration/${courseId}`,
      { params: query },
    );

    return result;
  }

  async GetFilters(appId: string, courseId: string) {
    const result: ExamFilters = await provider.get(
      `/api/generatedExam/${appId}/getExamsFilters/${courseId}`,
    );
    return result;
  }

  async updateExam(appId: string, courseId: string, scopeId: string,
    examId: string, query: object) {
    const result: string = await provider.put(
      `/api/generatedExam/${appId}/${courseId}/${examId}`,
      { ...query, scopeId },
    );

    return result;
  }

  async AddExam(appId: string, courseId: string, scopeId: string, examId: string, query: object) {
    const result: string = await provider.post(
      `/api/generatedExam/${appId}/${courseId}/${examId}`,
      { ...query, scopeId },
    );

    return result;
  }

  async getGeneratedExamData(
    appId: string, courseId: string, examId: string, query: GeneratedExamQuery = {},
  ): Promise<ExamData> {
    return provider.get(
      `/api/generatedExam/${appId}/getExamDataModel/${courseId}/${examId}`,
      { params: query },
    );
  }

  async getExamDataForEdit(
    appId: string, courseId: string, examId: string, query: GeneratedExamQuery = {},
  ): Promise<ExamData> {
    return provider.get(
      `/api/generatedExam/${appId}/getExamData/${courseId}/${examId}`,
      { params: query },
    );
  }

  async getAddFilters(appId: string, courseId: string):
    Promise<AddFiltersResponse> {
    return provider.get(`/api/generatedExam/${appId}/filters/${courseId}`);
  }

  async deleteGeneratedExamData(appId: string, courseId: string, examId: string):
    Promise<Lang> {
    return provider.delete(`/api/generatedExam/${appId}/${courseId}/${examId}`);
  }

  async copyExamData(appId: string, courseId: string, scopeId: string,
    examId: string, copyExamWithModels = false) {
    const result: {
      newExamId: string;
      message: Lang;
    } = await provider.get(`/api/generatedExam/${appId}/copyExam/${courseId}/${examId}`, {
      params: { copyExamWithModels },
    });

    return result;
  }

  async getGeneratedExamModelsData(appId: string, courseId: string, examId: string):
    Promise<ExamData> {
    return provider.get(`/api/generatedModels/${appId}/getModelData/${courseId}/${examId}`);
  }

  async getCellDetails(appId: string, courseId: string, subject: string, ilo: string):
    Promise<CellDetailsResponse> {
    return provider.get(`/api/generatedExam/${appId}/getCellDetails/${courseId}?subject=${subject}&ilo=${ilo}`);
  }

  async getModelDetails(appId: string, courseId: string, examId: string, modelNumber: string) {
    const result: ModelsDetails = await provider.get(
      `/api/generatedModels/${appId}/models/${courseId}/${examId}/${modelNumber}`,
    );

    return result;
  }

  async getAddEditQuestion(appId: string, courseId: string, langId?: string, trainingId?: string) {
    const result: QuestionsFilters = await provider.get(
      `/api/generatedExam/${appId}/getQuestionsFilters/${courseId}`,
      {
        params: {
          langId, trainingId,
        },
      },
    );
    return result;
  }

  async getCourseQuestions(appId: string, courseId: string, langId?: string, trainingId?: string, subject?: string, ilo?: string):
    Promise<CourseQuestionsData> {
    return provider.get(`/api/generatedExam/${appId}/getCourseQuestions/${courseId}`,
      {
        params: {
          subject, ilo, langId, trainingId,
        },
      });
  }

  async deleteModel(appId: string, courseId: string, examId: string, modelNumber: string):
  Promise<Lang> {
    return provider.delete(`/api/generatedModels/${appId}/models/${courseId}/${examId}/${modelNumber}`);
  }

  async publishExam(appId: string, courseId: string, examId: string, publishOption: string):
    Promise<{message: Lang}> {
    return provider.get(`/api/generatedExam/${appId}/publishExam/${courseId}/${examId}/${publishOption}`);
  }

  async distributeExamSectors(
    appId: string,
    courseId: string,
    examId: string,
    sectors: string[],
  ): Promise<{ success: boolean; message: Lang }> {
    return provider.post(`/api/generatedExam/${appId}/distribute/${courseId}/${examId}`, {
      sectors,
    });
  }
}
export default new GenerateExamProvider();
