import Vue from 'vue';
import Component from 'vue-class-component';
import { Route, Query } from '@/types/router.type';
import {
  SortData,
} from '@/types/questions.type';
import {
  GeneratedExamsInputs, ExamGenerationBasic, ExamGenerationListResponse,
} from '@/types/exam.type';
import ExamGenerationProvider from '@/providers/generateExam.provider';

@Component
export default class ExamsGenerationList extends Vue {
  appId = '';

  courseId = '';

  loading = false;

  loadingFilters = true;

  queries: Query = {};

  userRights: string[] = [];

  ExamsList: ExamGenerationBasic[] = [];

  totalItems = 0;

  limit = 10;

  page = 1

  error = '';

  filtersInputs: GeneratedExamsInputs = {
    academicYear: '',
    language: '',
    creator: [],
    status: [],
    examType: '',
    validFilters: false,
    semester: '',
    createDateFrom: '',
    createDateTo: '',
    examTitle: '',
  }

  headerClass = 'grey darken-4 white--text pa-3 qb-table__header';

  sortKeys: string[] = [];

  sortDesc: boolean[] = [];

  limitOption = [5, 10, 20, 50];

  get headerOptions() {
    return { sortByText: this.$t('labels.sortBy') };
  }

  get footerOptions() {
    return {
      'items-per-page-text': this.$t('RowsPerPage'),
      'items-per-page-all-text': this.$t('ALL'),
      'items-per-page-options': this.limitOption,
      disablePagination: this.loading,
      'show-first-last-page': true,
    };
  }

  created() {
    this.setQueries();
  }

  mounted() {
    this.fetchFiltersData();
  }

  setQueries() {
    const route: Route = this.$route as Route;
    this.queries = route.query;
    this.limit = Number(this.queries.limit) || 10;
    this.page = Number(this.queries.page) || 1;
    if (this.queries.orderBy !== undefined) {
      this.sortKeys[0] = this.queries.orderBy;
    }
    if (this.queries.desc === 'true') {
      this.sortDesc[0] = true;
    }
  }

  fetchFiltersData() {
    Object.keys(this.filtersInputs).forEach((key: string) => {
      if (key === 'status' || key === 'creator') {
        this.filtersInputs[key] = this.queries[key]?.length ? this.queries[key]?.split(',') || [] : [];
      } else {
        this.filtersInputs[key] = this.queries[key] ? this.queries[key] : '';
      }
    });
  }

  resetSearch() {
    Object.keys(this.filtersInputs).forEach((key: string) => {
      if (key !== 'validFilters') {
        this.filtersInputs[key] = '';
      }
    });
    this.search();
  }

  search() {
    const temp = this.filtersInputs.creator;
    const tempStatus = this.filtersInputs.status;
    const queryData = {
      ...this.filtersInputs,
      creator: temp.toString(),
      status: tempStatus.toString(),
    };
    this.filtersInputs.creator = temp;
    this.filtersInputs.status = tempStatus;
    delete queryData.validFilters;
    this.queries = { ...queryData } as unknown as Query;
    this.limit = this.limit || 10;
    this.page = 1;
    this.getExamsList();
  }

  onOptionUpdate(options) {
    this.page = options.page;
    this.limit = options.itemsPerPage;
    this.sortKeys = options.sortBy;
    this.sortDesc = options.sortDesc;
    this.getExamsList();
  }

  async getExamsList() {
    try {
      this.loading = true;

      delete this.queries.desc;
      const reqQuery = {
        ...this.queries,
        limit: `${this.limit}`,
        page: `${this.page}`,
        offset: `${(this.page - 1) * this.limit}`,
      };

      const sortData: SortData = { orderBy: this.sortKeys[0] };
      if (this.sortDesc[0]) {
        const [desc] = this.sortDesc;
        sortData.desc = desc.toString();
      }

      const {
        exams, totalCount, rights,
      }: ExamGenerationListResponse = await ExamGenerationProvider.getExamsGeneration(
        this.appId, this.courseId, { ...reqQuery, ...sortData },

      );

      this.loading = false;
      this.ExamsList = exams as ExamGenerationBasic[];
      this.userRights = rights as string[];

      this.totalItems = totalCount as number;

      this.$store.commit('AppFilters', {
        ...this.$store.state.filters.appFilters,
        [this.appId]: { ...reqQuery, ...sortData },
      });

      this.$router.replace({ query: { ...reqQuery, ...sortData } });
    } catch (err) {
      this.error = err;
      this.loading = false;
      this.ExamsList = [];
    }
  }
}
