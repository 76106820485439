


























































































































































































import {
  Component, Vue, Prop, Watch, Inject,
} from 'vue-property-decorator';
import {
  ExamDropInput,
  ExamsListProvider,
  GeneratedExamsInputs,
  ExamFilters,
  ExamGenerationResponse,
} from '../../types/exam.type';
import { BasicCreator } from '../../types/questions.type';

@Component
export default class ExamListFiltersComponent extends Vue {
  @Prop(String) readonly courseId!: string;

  @Prop(String) readonly appId!: string;

  @Prop(Object) readonly value!: GeneratedExamsInputs;

  @Prop() showReviewQuestionsFilters!: boolean;

  @Prop() createDateFromKey!: number;

  @Prop() createDateToKey!: number;

  @Inject() readonly Provider!: ExamsListProvider;

  loading = true;

  displayDateFrom = false;

  displayDateTo = false;

  currentDate = new Date().toISOString();

  filtersData: ExamGenerationResponse = {
    academicYears: [],
    languages: [],
    examStatus: [],
    examTypes: [],
    creators: [],
    semesters: [],
  };

  @Watch('filters', { deep: true })
  onfiltersChanged(newVal) {
    this.$emit('fetchFilters', newVal);
  }

  get reviewQuestionFilters() {
    return Boolean(this.showReviewQuestionsFilters);
  }

  get filtersInputs() {
    return this.value;
  }

  set filtersInputs(value) {
    this.$emit('input', value);
  }

  get lang() {
    return this.$store.state.lang.lang;
  }

  get filters(): ExamGenerationResponse {
    return {
      creators: this.filtersData.creators?.map((creator: BasicCreator) => ({
        ...creator,
        id: creator.userId,
        translatedName: this.$options.filters?.servertranslate(
          creator.userName
        ),
      })),
      languages: this.filtersData.languages?.map(
        (languagesItem: ExamDropInput) => ({
          ...languagesItem,
          id: languagesItem.baID,
          translatedName: this.$options.filters?.servertranslate(
            languagesItem.baName
          ),
        })
      ),
      examStatus: this.filtersData.examStatus?.map(
        (examStatusItem: ExamDropInput) => ({
          ...examStatusItem,
          id: examStatusItem.baID,
          translatedName: this.$options.filters?.servertranslate(
            examStatusItem.baName
          ),
        })
      ),
      examTypes: this.filtersData.examTypes?.map(
        (examTypesItem: ExamDropInput) => ({
          ...examTypesItem,
          id: examTypesItem.baID,
          translatedName: this.$options.filters?.servertranslate(
            examTypesItem.baName
          ),
        })
      ),
      semesters: this.filtersData.semesters?.map(
        (semestersItem: ExamDropInput) => ({
          ...semestersItem,
          id: semestersItem.baID,
          translatedName: this.$options.filters?.servertranslate(
            semestersItem.baName
          ),
        })
      ),
      academicYears: this.filtersData.academicYears?.map(
        (academicYearsItem: ExamDropInput) => ({
          ...academicYearsItem,
          id: academicYearsItem.baID,
          translatedName: this.$options.filters?.servertranslate(
            academicYearsItem.baName
          ),
        })
      ),
    };
  }

  doSearch() {
    if (this.filtersInputs.validFilters) {
      this.$emit('search');
    }
  }

  async mounted() {
    await this.fetchFiltersData();
  }

  checkCreationDateTo() {
    const result = new Date(this.filtersInputs.createDateFrom ?? '')
      > new Date(this.filtersInputs.createDateTo ?? '');
    if (result) {
      this.filtersInputs.createDateTo = '';
    }
  }

  get formattedDateFrom() {
    return this.formatDate(this.filtersInputs.createDateFrom);
  }

  get formattedDateTo() {
    return this.formatDate(this.filtersInputs.createDateTo);
  }

  async fetchFiltersData() {
    try {
      const {
        getFiltersListData,
      }: ExamFilters = await this.Provider.GetFilters(
        this.$store.state.app.activeApp,
        this.courseId,
      );

      if (getFiltersListData) {
        this.filtersData = getFiltersListData;
        this.loading = false;
      }
    } catch (err) {
      this.loading = false;
    }
  }

  rules = {
    examName: [
      (val: string) => val.trim().length <= 100
        || this.$t('validations.maxLength', { max: 100 }),
    ],
  };

  formatDate(date) {
    if (!date) return '';

    const [year, month, day] = date.split('-');
    return `${day}/${month}/${year}`;
  }
}
