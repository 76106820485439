












import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class PaginationComponent extends Vue {
    @Prop(Number) readonly currentPage!: number;

    @Prop(Number) readonly totalPages!: number;

    get page() {
      return this.currentPage;
    }

    set page(page: number) {
      this.$emit('input', page);
    }
}
