






















































































































































/* eslint-disable  no-underscore-dangle */

import { Component, Provide } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ExamListFilterComponent from '@/components/filters/ExamListFilter.component.vue';
import PaginationComponent from '@/components/pagination.component.vue';
import DialogComponent from '@/components/dialog.component.vue';

import ScopeComponent from '@/components/scope.component.vue';
import ExamGenerationProvider from '@/providers/generateExam.provider';
import {

  ExamGenerationResponse,

} from '../../types/exam.type';
import ExamsGenerationList from '../../mixins/ExamsGenerationList.mixin';

import {
  APPIDS, EXAM_GENERATION_ROUTE_NAME,
  Rights,
} from '../../enums';
import {
  DetailedQuestion,

} from '../../types/questions.type';

import SelectedCourse from '../SelectedCourse.component.vue';

@Component({
  components: {
    ExamListFilterComponent,
    PaginationComponent,
    DialogComponent,
    ScopeComponent,
    SelectedCourse,

  },
})
export default class ExamGenerationList extends mixins(ExamsGenerationList) {
  @Provide() Provider = ExamGenerationProvider;

  appId: string = APPIDS.EXAM_GENERATION;

  courseId: string = this.$route?.params.courseId;

  userRights: string[] = [];

  loadingDelete = false;

  showDeleteConfirm = false;

  toDeleteQuestionId = '';

  showAddDialog = false;

  filtersData: ExamGenerationResponse =
  {
    academicYears: [],
    languages: [],
    examStatus: [],
    examTypes: [],
    creators: [],
    semesters: [],
  };

  toAddQuestionType = '';

  showExportInfoDialog = false;

  questionToSimulate: DetailedQuestion = {};

  get headers() {
    return [

      {
        text: this.$t('tableHeaders.examTitle'),
        sortable: false,
        value: 'examTitle',
        align: 'center',
        class: this.headerClass,
      },

      {
        text: this.$t('tableHeaders.academicYear'),
        sortable: false,
        align: 'center',
        value: 'academicYear',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.examType'),
        sortable: false,
        align: 'center',
        value: 'examType',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.examStatus'),
        sortable: false,
        align: 'center',
        value: 'examStatus',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.semester'),
        sortable: false,
        align: 'center',
        value: 'semester',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.createdAt'),
        sortable: true,
        align: 'center',
        value: 'createdAt',
        class: this.headerClass,
      },
      {
        sortable: false,
        align: 'center',
        value: 'actions',
        class: this.headerClass,
        width: '150px',
      },
    ];
  }

  async mounted() {
    this.$store.commit('selectCourse', { course: { courseId: this.courseId } });
  }

  GenerateExam() {
    this.$router.push({
      name: EXAM_GENERATION_ROUTE_NAME.ADD_GENERATED_EXAM,
      params: {
        courseId: this.courseId,
      },
    });
  }

  goExamDetails(row) {
    this.$router.push({
      name: EXAM_GENERATION_ROUTE_NAME.EXAM_DETAILS,
      params: {
        courseId: this.courseId,
        examId: row._id,
      },
    });
  }

  EditExam(examId) {
    this.$router.push({
      name: EXAM_GENERATION_ROUTE_NAME.ADD_GENERATED_EXAM,
      params: {
        courseId: this.courseId,
        examId,
      },
    });
  }

  get canAdd() {
    return this.userRights.includes(Rights.add);
  }

  goCoursesList() {
    const { courseFilters } = this.$store.state.filters;
    this.$router.push({ name: EXAM_GENERATION_ROUTE_NAME.COURSES_LIST, query: courseFilters });
  }
}
